import React from 'react';

const Index = () => {
  return (
    <>
      <svg width="600" height="600" viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M188.889 95.7092C247.5 95.7092 232 155.556 300 155.556C370.5 155.556 351.5 95.7092 411.111 95.7092C464.5 95.7092 458 155.556 522.222 155.556C565.178 155.556 600 120.733 600 77.7778C600 34.8223 565.178 1.77535e-10 522.222 1.77535e-10C460 1.77351e-10 462.498 59.8463 411.111 59.8463C357.298 59.8463 367.5 0 300 1.77535e-10C232.5 3.5507e-10 246.778 59.8463 188.889 59.8463C131 59.8463 142.5 1.77351e-10 77.7778 1.77535e-10C34.8223 1.77657e-10 0 34.8223 0 77.7778C0 144.5 59.8463 131 59.8463 188.889C59.8463 243.5 0 234.5 0 300C0 342.955 34.8223 377.778 77.7778 377.778C146 377.778 135.278 317.931 188.889 317.931C242.5 317.931 236.5 377.778 300 377.778C367 377.778 353 317.931 411.111 317.931C447.884 317.931 504.291 359.366 504.291 411.111C504.291 462.856 447.884 504.291 411.111 504.291C357.5 504.291 365.5 444.444 300 444.444C234.5 444.444 244.278 504.291 188.889 504.291C133.5 504.291 142 444.444 77.7778 444.444C34.8223 444.444 0 479.267 0 522.222C0 565.178 34.8223 600 77.7778 600C142.5 600 133.5 540.154 188.889 540.154C246.5 540.154 231.5 600 300 600C365 600 360 540.154 411.111 540.154C469 540.154 455 600 522.222 600C565.178 600 600 565.178 600 522.222C600 460 540.154 466.722 540.154 411.111C540.154 355.5 600 361.5 600 300C600 257.044 565.178 222.222 522.222 222.222C456.5 222.222 466.5 282.069 411.111 282.069C354 282.069 368.5 222.222 300 222.222C235.5 222.222 243.5 282.069 188.889 282.069C154.287 282.069 95.7092 242.805 95.7092 188.889C95.7092 134.973 154.287 95.7092 188.889 95.7092Z" fill="url(#paint0_radial_604_6794)"/>
        <defs>
          <radialGradient id="paint0_radial_604_6794" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(498 -192.857) rotate(125.317) scale(1113.49 253.627)">
            <stop stopColor="#FFC875" offset="0"/>
            <stop offset="1" stopColor="#FF9630"/>
          </radialGradient>
        </defs>
      </svg>

    </>
  );
};

export default Index;