import React from 'react';
import TestimonialsTemplate from "../../../common/components/TestimonialsTemplate";
import useTranslation from "next-translate/useTranslation";
import {HOME_NAMESPACE} from "../../../../utils/i18nNameSpaces";

const Reviews = ({testimonials}) => {
  return (
    <>
      <TestimonialsTemplate testimonials={testimonials} namespace={HOME_NAMESPACE}/>
    </>
  );
};

export default Reviews;