import Star from "../../elements/Star";
import SwiperCore, {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import styles from "./styles.module.scss";
import cn from "classnames";
import useTranslation from "next-translate/useTranslation";
import _ from "lodash";
import TestimonialsText from "./TestimonialsText";
import {RC, RF, SEL} from "../../../../utils/constants";
import RFLogo from "../Icons/AppsLogos/RFLogo";
import SELLogo from "../Icons/AppsLogos/SELLogo";
import RCLogo from "../Icons/AppsLogos/RCLogo";

const starStyles = {
  width: 14,
  height: 15
}

const appsLogos = {
  [RF]: <RFLogo/>,
  [SEL]: <SELLogo/>,
  [RC]: <RCLogo/>
}


const TestimonialsTemplate = ({testimonials, namespace}) => {
  const {t} = useTranslation(namespace);
  if (_.isEmpty(testimonials)) {
    return null;
  }

  return (
    <section className={styles["reviews"]}>
      <div className={styles["reviews__container"]}>
        <div className={styles["reviews__info"]}>
          <h4 className={styles["reviews__header"]}>{t("testimonials.header")}</h4>
          <p className={styles["reviews__subheader"]}>{t("testimonials.subheader")}</p>
        </div>
        <div
          className={cn(styles["reviews__slider-wrapper"], {[styles["reviews__slider-wrapper--one"]]: testimonials.length === 1}, "reviews__slider-wrapper")}>
          <Swiper
            modules={[Autoplay, Pagination]}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false
            }}
            breakpoints={{
              819: {
                slidesPerView: testimonials.length === 1 ? 1 : 2,
                spaceBetween: 20
              },
            }}
            // className="mySwiper"
          >
            {testimonials.map((testimonial, i) => (
              <SwiperSlide key={i}>
                <div key={i} className={styles["slide__container"]}>
                  <div className={styles["slide__content"]}>
                    <TestimonialsText text={testimonial.text}/>
                  </div>
                  <footer className={styles["slide__footer"]}>
                    {
                      testimonial.appName &&
                      <div className={styles["slide__img-wrapper"]}>
                        {appsLogos[testimonial.appName]}
                      </div>
                    }
                    <div className={styles["slide__footer-review"]}>
                      <div className={styles["stars-wrapper"]}>
                        <Star color="#ffc20a" className={styles["star"]} starStyles={starStyles}/>
                        <Star color="#ffc20a" className={styles["star"]} starStyles={starStyles}/>
                        <Star color="#ffc20a" className={styles["star"]} starStyles={starStyles}/>
                        <Star color="#ffc20a" className={styles["star"]} starStyles={starStyles}/>
                        <Star color="#ffc20a" className={styles["star"]} starStyles={starStyles}/>
                      </div>
                      <span className={styles["slide__app-name"]}>{testimonial.shopName}</span>
                    </div>
                  </footer>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsTemplate;