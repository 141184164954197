import React, {useEffect, useRef, useState} from 'react';
import {Tooltip} from "@shopify/polaris";
import styles from "../styles.module.scss";
import cn from "classnames";

const heightForThreeDots = 120;

const TestimonialsText = ({text}) => {
  const [shouldTooltip, setShouldTooltip] = useState(false);
  const testimonialText = useRef(null);

  useEffect(() => {
    const compStyles = getComputedStyle(testimonialText.current);
    const height = parseInt(compStyles.height);

    if (height > heightForThreeDots) {
      setShouldTooltip(true);
    }

  }, [testimonialText, shouldTooltip, text]);

  return (
    <>
      {
        shouldTooltip ?
          <Tooltip content={text}>
            <p className={cn(styles["slide__text"], styles["three-dots"])} ref={testimonialText}>{text}</p>
          </Tooltip>
          :
          <p className={cn(styles["slide__text"])} ref={testimonialText}>{text}</p>
      }
    </>
  );
};

export default TestimonialsText;